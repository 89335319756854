import React, { useEffect, useState } from "react";
import { Box, Grid, Icon, IconButton } from "@material-ui/core";
import axios from "app/utils/AxiosConfig";
import Constants from "app/utils/Constants";
import moment from "moment-timezone";
import Store from "app/utils/Store";
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import CommonForm from "app/components/form/CommonForm";
const user = Store.USER;
const ProposalTimeCheck = ({ proposal }) => {
  const [timeCheck, setTimeCheck] = useState([]);
  const [timeSelected, setTimeSelected] = useState([]);
  const [timeDeleteDialog, setTimeDeleteDialog] = useState(false);
  const [editMode, setEditMode] = useState([]);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [status, setStatus] = useState([]);
  useEffect(() => {
    getData();
    getStatus();
  }, []);

  const getData = () => {
    axios.get(Constants.APIEndpoints.PROPOSAL + "/gettimecheck/" + proposal.id_proposals).then((res) => {
      setTimeCheck(res.data);
    });
  };

  const getStatus = () => {
    axios
      .post(Constants.APIEndpoints.PROPOSAL + "/getallgeneraldata", {
        entities: ["statusw"],
      })
      .then((res) => {
        setStatus(res.data.status);
      });
  };

  const getDiffTime = (time) => {
    const lastDate = (timeCheck || []).find((s) => s.dt_cad < time.dt_cad);
    if (lastDate) {
      const diff = moment(time.dt_cad).diff(moment(lastDate.dt_cad), "seconds");
      const days = parseInt(diff / 86400);
      return `${days} dia${days == 1 ? "" : "s"}`;
    }
    return "";
  };

  const saveDate = () => {
    axios.post(Constants.APIEndpoints.PROPOSAL + "/updatetimecheck", editMode).then((res) => {
      setEditMode([]);
      getData();
    });
  };

  const createDate = () => {
    axios
      .post(Constants.APIEndpoints.PROPOSAL + "/savetimecheck", {
        ...timeSelected,
        fk_id_proposals: proposal.id_proposals,
      })
      .then((res) => {
        setTimeSelected([]);
        setOpenCreateDialog(false);
        getData();
      });
  };

  const deleteDate = () => {
    axios.post(Constants.APIEndpoints.PROPOSAL + "/deletetimecheck", timeSelected).then((res) => {
      getData();
      setTimeDeleteDialog(false);
    });
  };

  console.log("time", timeCheck);

  return (
    <div>
      {timeDeleteDialog ? (
        <ConfirmDialog title="Deseja deletar essa data?" cancel={() => setTimeDeleteDialog(false)} confirm={deleteDate} />
      ) : null}

      <div style={{ marginTop: 60 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1} style={{ backgroundColor: "white" }}>
            <Grid item xs={12}>
              <div className="cardProposal">
                {openCreateDialog ? (
                  <CommonForm
                    fields={[
                      {
                        col: 6,
                        name: "fk_id_status",
                        label: "Status",
                        type: "select",
                        options: status
                          .filter((s) => !timeCheck.map((t) => t.fk_id_status).includes(s.id_status))
                          .map((s) => ({ value: s.id_status, label: s.name })),
                      },
                      {
                        col: 6,
                        type: "date",
                        name: "date",
                        label: "Data",
                      },
                    ]}
                    values={timeSelected}
                    onChangeField={(f, v) => {
                      setTimeSelected((prev) => ({ ...prev, [f.name]: v }));
                    }}
                    onSubmit={createDate}
                  />
                ) : null}
                <div>
                  {(user.role == "admin" || user.access?.proposal_change_date_status) && (
                    <div>
                      <IconButton onClick={(ev) => setOpenCreateDialog(true)}>
                        <Icon>add</Icon>
                      </IconButton>
                      {openCreateDialog && (
                        <IconButton
                          onClick={(ev) => {
                            setTimeSelected([]);
                            setOpenCreateDialog(false);
                          }}
                        >
                          <Icon>clear</Icon>
                        </IconButton>
                      )}
                    </div>
                  )}
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th
                          style={{
                            color: "black",
                            width: "25%",
                            textAlign: "start",
                            fontSize: 16,
                          }}
                        >
                          Status
                        </th>
                        <th
                          style={{
                            color: "black",
                            width: "25%",
                            textAlign: "start",
                            fontSize: 16,
                          }}
                        >
                          Data
                        </th>
                        <th
                          style={{
                            color: "black",
                            width: "25%",
                            textAlign: "start",
                            fontSize: 16,
                          }}
                        >
                          Tempo Até o Status
                        </th>
                        <th
                          style={{
                            color: "black",
                            width: "25%",
                            textAlign: "start",
                            fontSize: 16,
                          }}
                        >
                          Excluir
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {timeCheck.map((time) => (
                        <tr key={time.id_change_proposal_status} style={{ color: "black" }}>
                          <td className="table_td" style={{ textAlign: "start" }}>
                            {time.name}
                          </td>
                          <td className="table_td" style={{ textAlign: "start" }}>
                            <div>
                              {user.role == "admin" || user.access?.proposal_change_date_status ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {editMode.id_proposals_change_status === time.id_proposals_change_status ? (
                                    <div>
                                      <input
                                        type="date"
                                        value={moment(editMode.dt_cad).format("YYYY-MM-DD")}
                                        onChange={(evt) =>
                                          setEditMode((prev) => ({
                                            ...prev,
                                            dt_cad: evt.target.value,
                                          }))
                                        }
                                      ></input>
                                    </div>
                                  ) : (
                                    <div>{moment(time.dt_cad).format("DD/MM/YYYY")}</div>
                                  )}

                                  <IconButton
                                    style={{
                                      justifyContent: "start",
                                      width: 45,
                                      borderRadius: 0,
                                    }}
                                    onClick={(ev) => {
                                      editMode.id_proposals_change_status === time.id_proposals_change_status
                                        ? saveDate()
                                        : setEditMode(time);
                                    }}
                                  >
                                    <Icon>{editMode.id_proposals_change_status === time.id_proposals_change_status ? "save" : "edit"}</Icon>
                                  </IconButton>
                                </div>
                              ) : (
                                <div>{moment(time.dt_cad).format("DD/MM/YYYY")}</div>
                              )}
                            </div>
                          </td>
                          <td className="table_td" style={{ textAlign: "start" }}>
                            {getDiffTime(time)}
                          </td>
                          <td>
                            {(user.role == "admin" || user.access?.proposal_change_date_status) && (
                              <IconButton
                                style={{
                                  justifyContent: "start",
                                  width: 45,
                                  borderRadius: 0,
                                }}
                                onClick={(ev) => {
                                  setTimeSelected(time);
                                  setTimeDeleteDialog(true);
                                }}
                              >
                                <Icon>delete</Icon>
                              </IconButton>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default ProposalTimeCheck;
